import BasePlugin from '../BasePlugin'
import ImportBudgetIncomeAndExpensesFromRequestCmp from './ImportBudgetIncomeAndExpensesFromRequestCmp'

export default class ImportBudgetIncomeAndExpensesFromRequest extends BasePlugin {
  async execute () {
    let requestId = this.context.getModel().id
    const h = this.context.$createElement
    this.context.$msgbox({
      title: 'Выберите файл для загрузки',
      message: h('p', { key: Math.random() }, [
        h(ImportBudgetIncomeAndExpensesFromRequestCmp, { props: { model: requestId, component: this.context.getDashboardComponents()['component_913f93bb-1168-4fd0-9cdd-ce0713144864'][0] } })
      ]),
      showCancelButton: false,
      showConfirmButton: false
    })
  }
}
